<template>
  <PageWithLayout>
    <TableView>
      <template v-slot:tbl_colgroup>
        <col style="width:190px"/>
        <col/>
      </template>
      <template v-slot:tbl_top>
        <div class="area_left">
          <strong class="tit_tbl">직원 등록</strong>
        </div>
      </template>
      <template v-slot:tbl_body>
        <tr>
          <th>권한<IconSvg iconName="essential" :size="6" iconColor="#F04848"/></th>
          <td>
            <Radio
              :dataList="'member_role' | getSelectDataList"
              :value.sync="viewModel.model.role"/>
          </td>
        </tr>
        <tr>
          <th>이름<IconSvg iconName="essential" :size="6" iconColor="#F04848"/></th>
          <td>
            <Input
              placeholder="이름을 입력해주세요"
              maxWidth="300px"
              :value.sync="viewModel.model.name"
              :valueType="viewModel.registerValid.name.valueType"
              :isError.sync="viewModel.registerValid.name.isValid"
              :errorDesc="viewModel.registerValid.name.validAlertDesc"/>
          </td>
        </tr>
        <tr>
          <th>이메일<IconSvg iconName="essential" :size="6" iconColor="#F04848"/></th>
          <td>
            <Input
              placeholder="이메일을 입력해주세요"
              maxWidth="300px"
              :value.sync="viewModel.model.email"
              :valueType="viewModel.registerValid.email.valueType"
              :isError.sync="viewModel.registerValid.email.isValid"
              :errorDesc="viewModel.registerValid.email.validAlertDesc"/>
          </td>
        </tr>
        <tr>
          <th>비밀번호<IconSvg iconName="essential" :size="6" iconColor="#F04848"/></th>
          <td>
            <Input
              placeholder="비밀번호를 입력해주세요"
              maxWidth="300px"
              type="password"
              :value.sync="viewModel.model.password"
              :valueType="viewModel.registerValid.password.valueType"
              :isError.sync="viewModel.registerValid.password.isValid"
              :errorDesc="viewModel.registerValid.password.validAlertDesc"
              :referenceErrorDesc="viewModel.registerValid.password.referenceVaildAlertDesc"/>
          </td>
        </tr>
        <tr>
          <th>비밀번호 확인<IconSvg iconName="essential" :size="6" iconColor="#F04848"/></th>
          <td>
            <Input
              placeholder="비밀번호를 한 번 더 입력해주세요"
              maxWidth="300px"
              type="password"
              :value.sync="viewModel.model.repeatPassword"
              :referenceValue="viewModel.model[viewModel.registerValid.repeatPassword.referenceValue]"
              :valueType="viewModel.registerValid.repeatPassword.valueType"
              :isError.sync="viewModel.registerValid.repeatPassword.isValid"
              :errorDesc="viewModel.registerValid.repeatPassword.validAlertDesc"
              :referenceErrorDesc="viewModel.registerValid.repeatPassword.referenceVaildAlertDesc"/>
          </td>
        </tr>
        <tr>
          <th>이미지</th>
          <td>
            <FileUpload
              :dataList.sync="viewModel.model.imageList"
              :canMultiple="false"
              :acceptList="['jpg','jpeg','png']"
              :size="[800,800]"
              sizeType="MIN"/>
          </td>
        </tr>
        <!-- <tr>
          <th>이미지 미리보기</th>
          <td>
            <div class="img_profile">
              <img
                :src="viewModel.model.imageList.length > 0 ? viewModel.model.imageList[0].imgPath : ''"
                class="img_g"
                alt="profile"
                onerror="this.src='/assets/images/profile_default.png'">
            </div>
          </td>
        </tr> -->
      </template>
    </TableView>
    <Sticky>
      <div class="area_left">
        <Button
          btnSize="medium"
          btnStyle="secondary_border"
          text="취소"
          routeName="ADMIN_MEMBER_LIST"/>
      </div>
      <div class="area_right">
        <Button
          btnSize="medium"
          btnStyle="primary"
          text="저장"
          :disabled="isVaild"
          @onClickBtn="viewModel.onClickCompelete()"/>
      </div>
    </Sticky>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from '@lemontree-ai/lemontree-admin-common-front/components/layout/PageWithLayout';
import TableView from '@lemontree-ai/lemontree-admin-common-front/components/common/table/TableView';
import IconSvg from '@lemontree-ai/lemontree-admin-common-front/components/common/icon/IconSvg';
import Input from '@lemontree-ai/lemontree-admin-common-front/components/common/input/Input';
import Radio from '@lemontree-ai/lemontree-admin-common-front/components/common/radio/Radio';
import FileUpload from '@lemontree-ai/lemontree-admin-common-front/components/common/file/FileUpload';
import Sticky from '@lemontree-ai/lemontree-admin-common-front/components/common/sticky/Sticky';
import Button from '@lemontree-ai/lemontree-admin-common-front/components/common/button/Button';
// viewModel
import MemberDetailViewModel from '@/views/admin/member/viewModel/MemberDetailViewModel'

export default {
  name:'MemberDetail',
  components:{
    PageWithLayout,
    TableView,
    IconSvg,
    Input,
    Radio,
    FileUpload,
    Sticky,
    Button,
  },
  beforeMount(){
    const id = this.$route.params.id
    this.viewModel.init(id);
  },
  data(){
    return{
      viewModel: new MemberDetailViewModel(),
    }
  },
  computed:{
    isVaild(){
      return this.viewModel.registerValidatedResult()
    }
  }
}
</script>
<style scoped>
.img_profile{overflow:hidden;width:150px;border-radius:6px}
</style>